import "./styles.css";
import { useEffect, useState } from "react";
import Section from "../../../../components/section/Section";
import Row from "../../../../components/row/Row";
import SearchBox from "../../../../components/search-box/SearchBox";
import { Dictionary, Abbreviations } from "../../../../services/Dictionary";
////////////////////
import Inflector from "../../../../services/Inflector";

const Searcher = (props) => {

    const LOOKFOR_CONLANG = 0;
    const LOOKFOR_OWN_LANGUAGE = 1;

    const [cache, setCache] = useState([]);
    const [lookFor, setLookFor] = useState(LOOKFOR_OWN_LANGUAGE);
    const [wordActive, setWordActive] = useState("");

    const inflector = new Inflector();

    const getAll = () => {
        let words = [];
        words = words.concat(Object.values(Dictionary.words));
        words.sort((a, b) => a.form.localeCompare(b.form));
        return words;
    }

    const getTypes = (word) => {
        return Object.keys(word.type);
    }

    const hasInflections = (word) => {
        let types = Object.keys(word.type);
        return types.includes("adjective") || types.includes("noun") || types.includes("verb");
    }

    const sanitizeWord = (word) => {
        word = word.toLowerCase();
        word = word.replace(/[áäàâå]/g, "a");
        word = word.replace(/[éëèê]/g, "e");
        word = word.replace(/[íïìî]/g, "i");
        word = word.replace(/[óöòôøǿ]/g, "o");
        word = word.replace(/[úüùû]/g, "u");
        word = word.replace(/[ñ]/g, "n");
        word = word.replace(/[ꝟ]/g, "v");
        return word;
    }

    const searchBy = (critery) => {
        let buffer = [];
        critery = sanitizeWord(critery);
        Object.keys(Dictionary.words).forEach(w => {
            if (lookFor === LOOKFOR_CONLANG) {
                if (sanitizeWord(Dictionary.words[w].form).includes(critery)) {
                    if (!buffer.includes(Dictionary.words[w])) {
                        buffer.push(Dictionary.words[w]);
                    }
                }
            }
            if (lookFor === LOOKFOR_OWN_LANGUAGE) {
                Object.keys(Dictionary.words[w].type).forEach(t => {
                    Dictionary.words[w].type[t][props.language].forEach(m => {
                        if (sanitizeWord(m).includes(critery)) {
                            if (!buffer.includes(Dictionary.words[w])) {
                                buffer.push(Dictionary.words[w]);
                            }
                        }
                    });
                });
            }
        });
        setCache(buffer);
    }

    const switchLanguage = () => {
        if (lookFor === LOOKFOR_CONLANG) {
            setLookFor(LOOKFOR_OWN_LANGUAGE);
        } else if (lookFor === LOOKFOR_OWN_LANGUAGE) {
            setLookFor(LOOKFOR_CONLANG);
        }
    }

    useEffect(() => {
        setCache(getAll());
    }, []);

    return (
        <Section>
            <SearchBox
                texts={props.texts}
                language={props.language}
                onSearch={c => searchBy(c)}
                results={cache.length}
                switchedLanguage={lookFor === LOOKFOR_CONLANG ? props.texts.conlang : props.texts.language[props.language]}
                onSwitchLanguage={switchLanguage}
            />
            {cache.map((w) => <>
                <Row separator>
                    <div className="word-item" onClick={e => hasInflections(w) ? setWordActive(w.form) : null}>
                        <div className="word">
                            <span className="form">{ w.form }</span>
                            <span className="ipa">[{ w.ipa.inflected ? w.ipa.root : w.ipa }]</span>
                        </div>
                        {getTypes(w).map(t =>
                            <div key={`${w.form}-${t}`.form}>
                                <span className="type">
                                    <span className="type-abbr">({Abbreviations[t][props.language]}.)</span>
                                    <span className="highlight">{w.type[t][props.language].join(", ")}.</span>
                                </span>
                            </div>
                        )}
                        {hasInflections(w) ? <>
                            <div className={`list ${wordActive === w.form ? "active" : ""}`} word={w.form}>
                                {w.type.adjective ? <>
                                    <div>{ Object.entries(inflector.do(w)).map(i => <>
                                        {i[0] === "adjective" && i[0] !== null ? Object.entries(i[1]).map(t => <>
                                            {"plural" === t[0] ? <div>
                                                <span className="type-abbr">({ props.inflections.adjective[t[0]][props.language] })</span>
                                                <span className="form highlight">{ t[1].form }</span>
                                                <span className="ipa">[{ t[1].ipa }]</span>
                                            </div> : null}
                                        </>) : null}
                                    </>) }</div>
                                </> : null}
                                {w.type.noun ? <>
                                    <div>{ Object.entries(inflector.do(w)).map(i => <>
                                        {i[0] === "noun" && i[0] !== null ? Object.entries(i[1]).map(t => <>
                                            {"plural" === t[0] ? <div>
                                                <span className="type-abbr">({ props.inflections.noun[t[0]][props.language] })</span>
                                                <span className="form highlight">{ t[1].form }</span>
                                                <span className="ipa">[{ t[1].ipa }]</span>
                                            </div> : null}
                                        </>) : null}
                                    </>) }</div>
                                </> : null}
                                {w.type.verb ? <>
                                    <div>{ Object.entries(inflector.do(w)).map(i => <>
                                        {i[0] === "verb" && i[0].verb !== null ? Object.entries(i[1]).map(t => <>
                                            {["nonPast", "imperfectPreterite", "perfectPreterite"].includes(t[0]) ? <div>
                                                <span className="type-abbr">({ props.inflections.verb[t[0]][props.language] })</span>
                                                <span className="form highlight">{ t[1].form }</span>
                                                <span className="ipa">[{ t[1].ipa }]</span>
                                            </div> : null}
                                        </>) : null}
                                    </>) }</div>
                                </> : null}
                            </div>
                        </>: null}
                        {/*
                        */}
                    </div>
                </Row>
            </>)}
        </Section>
    );
}

export default Searcher;