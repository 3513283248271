import { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Texts from "../../services/Texts";
import Searcher from "./content/searcher/Searcher";

const MainIndex = () => {

	const LANGUAGE_SPANISH = "es";
	const LANGUAGE_ENGLISH = "en";

	const DEFAULT_LANGUAGE = LANGUAGE_SPANISH;

	const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
	const txt = Texts;

	const toggleLanguage = () => {
		setLanguage(e => e === LANGUAGE_SPANISH ? LANGUAGE_ENGLISH : LANGUAGE_SPANISH)
	}

	return (<>
		<Navbar
			language={language}
			title={txt.title}
			author={txt.author.full[language]}
			manual={txt.menu.manual[language]}
			dictionary={txt.menu.dictionary[language]}
			onChangeLanguage={toggleLanguage}
		/>
		<Searcher
			language={language}
			texts={txt.searcher}
			inflections={txt.inflections}
		/>
		<Footer title={txt.title} />
	</>);
}

export default MainIndex;