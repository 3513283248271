import "./styles.css";

const Footer = (props) => {

    const initYear = 2024;
    const currentYear = new Date().getFullYear();

    return (<>
        <div className="footer">Copyright { initYear === currentYear ? initYear : `${initYear} ~ ${currentYear}` } &copy; {props.title}</div>
    </>);

}

export default Footer;