import "./styles.css";
import { useState } from "react";

const SearchBox = (props) => {

    const [critery, setCritery] = useState("");


    const searchBy = (text) => {
        props.onSearch(text);
        setCritery(text);
    }

    return (<>
        <div className="search-wrapper">
            <div className="form-group">
                <label htmlFor="critery">{ props.texts.placeholder[props.language] }</label>
                <input
                    type="text"
                    id="critery"
                    value={critery}
                    onChange={e => searchBy(e.target.value)}
                />
            </div>
            <div className="search-toolbar">
                <div>
                    <span>{ props.texts.filterBy[props.language] }</span>
                    <span className="btn-tag" onClick={e => props.onSwitchLanguage()}>
                        { props.switchedLanguage.toLowerCase() }
                    </span>
                </div>
                <div className="results">
                    <span style={{textAlign: "right"}}>{ props.texts.results[props.language] }</span>
                    <span className="results-badge">{ props.results }</span>
                </div>
            </div>
        </div>
    </>);
}

export default SearchBox;