const Abbreviations = {
    adjective: {
        es: "adj",
        en: "adj",
    },
    adverb: {
        es: "adv",
        en: "adv",
    },
    auxiliary: {
        es: "aux",
        en: "aux",
    },
    preposition: {
        es: "prep",
        en: "prep",
    },
    conjunction: {
        es: "conj",
        en: "conj",
    },
    determiner: {
        es: "det",
        en: "det",
    },
    pronoun: {
        es: "pron",
        en: "pron",
    },
    noun: {
        es: "s",
        en: "n",
    },
    verb: {
        es: "v",
        en: "v",
    },
    number: {
        es: "nro",
        en: "no",
    },
    phrase: {
        es: "fr",
        en: "phr"
    }
}

const Dictionary = {
    words: {
        oy: {
            form: "øy",
            ipa: "‘oɪ",
            type: {
                pronoun: {
                    es: ["yo"],
                    en: ["I"],
                },
            },
        },
        svi: {
            form: "sví",
            ipa: "‘svi:",
            type: {
                pronoun: {
                    es: ["tú", "vos"],
                    en: ["you"],
                },
            },
        },
        kne: {
            form: "kné",
            ipa: "‘neɪ",
            type: {
                pronoun: {
                    es: ["él"],
                    en: ["he"],
                },
            },
        },
        fjau: {
            form: "fjau",
            ipa: "'fjau",
            type: {
                pronoun: {
                    es: ["ella"],
                    en: ["she"],
                },
            },
        },
        har: {
            form: "hår",
            ipa: "'ha:",
            type: {
                pronoun: {
                    es: ["aquél", "aquélla", "aquello", "ése", "éste", "eso", "esto"],
                    en: ["it", "that", "this"],
                },
                determiner: {
                    es: ["aquel", "aquella", "esa", "ese", "esta", "este", "esto"],
                    en: ["that", "this"],
                },
            },
        },
        konen: {
            form: "kǿnen",
            ipa: "'kø:nen",
            type: {
                pronoun: {
                    es: ["nosotros"],
                    en: ["we"],
                },
            },
        },
        sveigr: {
            form: "svéigr",
            ipa: "'svi:a",
            type: {
                pronoun: {
                    es: ["ustedes"],
                    en: ["you all"],
                },
            },
        },
        kno: {
            form: "knǿ",
            ipa: "'nø:",
            type: {
                pronoun: {
                    es: ["ellos"],
                    en: ["they"],
                },
            },
        },
        fjo: {
            form: "fjó",
            ipa: "'fjou",
            type: {
                pronoun: {
                    es: ["ellas"],
                    en: ["they"],
                },
            },
        },
        heigr: {
            form: "héigr",
            ipa: "'hi:a",
            type: {
                pronoun: {
                    es: ["aquéllas", "aquéllos", "ésas", "ésos", "éstos", "éstas"],
                    en: ["they", "these", "those"],
                },
                determiner: {
                    es: ["aquellas", "aquellos", "esas", "esos", "estas", "estos"],
                    en: ["that", "this"],
                },
            },
        },
        minr: {
            form: "minr",
            ipa: "'mina",
            type: {
                pronoun: {
                    es: ["mi", "mío"],
                    en: ["my", "mine"],
                },
            },
        },
        nor: {
            form: "nór",
            ipa: "'no:",
            type: {
                pronoun: {
                    es: ["nuestro"],
                    en: ["our", "ours"],
                },
            },
        },
        svies: {
            form: "svíes",
            ipa: "'svi:es",
            type: {
                pronoun: {
                    es: ["su", "suyo", "tu", "tuyo"],
                    en: ["your", "yours"],
                },
            },
        },
        svoes: {
            form: "svǿes",
            ipa: "'svø:es",
            type: {
                pronoun: {
                    es: ["su", "suyo", "vuestro", "vuestro"],
                    en: ["your", "yours"],
                },
            },
        },
        es: {
            form: "es",
            ipa: "'es",
            type: {
                pronoun: {
                    es: ["su", "suyo"],
                    en: ["their", "theirs"],
                },
            },
        },
        am: {
            form: "åm",
            ipa: "a:m",
            type: {
                preposition: {
                    es: ["a", "en", "hacia", "sobre"],
                    en: ["at", "in", "on", "over", "to", "towards",],
                },
            },
        },
        ans: {
            form: "åns",
            ipa: "a:ns",
            type: {
                adverb: {
                    es: ["a punto de"],
                    en: ["about to"],
                },
                preposition: {
                    es: ["acerca de"],
                    en: ["about"],
                },
            },
        },
        ende: {
            form: "ende",
            ipa: "ende",
            type: {
                preposition: {
                    es: ["adentro", "dentro", "dentro de"],
                    en: ["inside", "inside the", "inside of"],
                },
            },
        },
        et: {
            form: "et",
            ipa: "et",
            type: {
                conjunction: {
                    es: ["y", "e"],
                    en: ["and"],
                },
            },
        },
        evest: {
            form: "évest",
            ipa: "'eɪvest",
            type: {
                verb: {
                    es: ["funcionar", "trabajar"],
                    en: ["to work"],
                },
            },
        },
        streik: {
            form: "stréik",
            ipa: "'stɾi:k",
            type: {
                verb: {
                    es: ["destrozar", "destruir", "romper"],
                    en: ["to break", "to shatter"],
                },
            },
        },
        svodr: {
            form: "svódr",
            ipa: "'svo:da",
            type: {
                noun: {
                    es: ["esposa"],
                    en: ["wife"],
                },
            },
        },
        hevdigr: {
            form: "hevdig",
            ipa: {
                root: "'hefdih",
                inflected: "'hefdi.",
            },
            type: {
                noun: {
                    es: ["esposo"],
                    en: ["husband"],
                },
            },
        },
        hedan: {
            form: "hédan",
            ipa: "'heɪdan",
            type: {
                noun: {
                    es: ["hija", "hijo"],
                    en: ["child", "daughter", "son"],
                },
            },
        },
        fnil: {
            form: "fnil",
            ipa: "'fnil",
            irregular: {
                adjective: {
                    plural: {
                        form: "fnil",
                        ipa: "'fnil",
                    },
                }
            },
            type: {
                adjective: {
                    es: ["mismo"],
                    en: ["same"],
                },
                pronoun: {
                    es: ["uno mismo"],
                    en: ["self", "oneself"],
                },
            },
        },
        os: {
            form: "øs",
            ipa: "'œ:s",
            type: {
                adverb: {
                    es: ["ahora"],
                    en: ["now"],
                },
            },
        },
        noksr: {
            form: "nǿksr",
            ipa: "'nø:ksa",
            type: {
                noun: {
                    es: ["odio"],
                    en: ["hate"],
                },
            },
        },
        ovnoks: {
            form: "øvnǿks",
            ipa: "'œ:fnøks",
            type: {
                verb: {
                    es: ["odiar"],
                    en: ["to hate"],
                },
            },
        },
        frost: {
            form: "frøst",
            ipa: "'fɾœ:st",
            type: {
                adjective: {
                    es: ["frío"],
                    en: ["cold"],
                },
                verb: {
                    es: ["enfriar", "enfriarse"],
                    en: ["to cool", "to cooldown"],
                },
            },
        },
        streiv: {
            form: "stréiv",
            ipa: "'stɾi:f",
            irregular: {
                adjective: {
                    plural: {
                        form: "stréiv",
                        ipa: "'stɾi:f",
                    },
                },
                noun: {
                    plural: {
                        form: "stréiv",
                        ipa: "'stɾi:f",
                    },
                },
            },
            type: {
                adjective: {
                    es: ["derecha"],
                    en: ["right"],
                },
                noun: {
                    es: ["derecha"],
                    en: ["right"],
                },
            },
        },
        vauf: {
            form: "ꝟauf",
            ipa: "'vaʊf",
            irregular: {
                adjective: {
                    plural: {
                        form: "ꝟauf",
                        ipa: "'vaʊf",
                    },
                },
                noun: {
                    plural: {
                        form: "ꝟauf",
                        ipa: "'vaʊf",
                    },
                },
            },
            type: {
                adjective: {
                    es: ["izquierda"],
                    en: ["left"],
                },
                noun: {
                    es: ["izquierda"],
                    en: ["left"],
                },
            },
        },
        augr: {
            form: "augr",
            ipa: "'aʊga",
            type: {
                noun: {
                    es: ["tiempo"],
                    en: ["time"],
                },
            },
        },
        fre: {
            form: "fré",
            ipa: "'fɾe:",
            type: {
                adverb: {
                    es: ["ahí", "allá", "allí"],
                    en: ["there"],
                },
            },
        },
        fseg: {
            form: "fség",
            ipa: {
                root: "'fse:h",
                inflected: "'fse:g",
            },
            type: {
                verb: {
                    es: ["preguntar"],
                    en: ["to ask"],
                },
            },
        },
        sveit: {
            form: "svéit",
            ipa: "'svi:t",
            type: {
                verb: {
                    es: ["preguntarse"],
                    en: ["to wonder"],
                },
            },
        },
        fulend: {
            form: "fúlend",
            ipa: {
                root: "'fʉ:len",
                inflected: "'fʉ:lend",
            },
            type: {
                noun: {
                    es: ["mujer"],
                    en: ["woman"],
                },
            },
        },
        falst: {
            form: "fålst",
            ipa: "'fa:lst",
            type: {
                verb: {
                    es: ["viajar"],
                    en: ["to travel"],
                },
            },
        },
        flaskr: {
            form: "flåskr",
            ipa: "'fla:ska",
            type: {
                noun: {
                    es: ["cosa"],
                    en: ["stuff", "thing"],
                },
                adjective: {
                    es: ["algún"],
                    en: ["some"],
                },
                pronoun: {
                    es: ["algo"],
                    en: ["something"],
                },
            },
        },
        gjolst: {
            form: "gjølst",
            ipa: "'jœ:lst",
            type: {
                verb: {
                    es: ["crecer"],
                    en: ["to grow"],
                },
            },
        },
        nog: {
            form: "nog",
            ipa: "noh",
            type: {
                preposition: {
                    es: ["con", "junto a"],
                    en: ["together", "with"],
                },
            },
        },
        omsu: {
            form: "omsu",
            ipa: "u:msʊ",
            type: {
                conjunction: {
                    es: ["que"],
                    en: ["to", "that"],
                },
            },
        },
        im: {
            form: "im",
            ipa: "im",
            type: {
                preposition: {
                    es: ["durante"],
                    en: ["during"],
                },
                adverb: {
                    es: ["mientras"],
                    en: ["as"],
                },
                conjunction: {
                    es: ["mientras", "mientras que"],
                    en: ["while"],
                },
            },
        },
        on: {
            form: "on",
            ipa: "on",
            irregular: {
                adjective: {
                    plural: {
                        form: "on",
                        ipa: "on",
                    }
                }
            },
            type: {
                adverb: {
                    es: ["como"],
                    en: ["as", "like"],
                },
                adjective: {
                    es: ["como"],
                    en: ["like"],
                },
                preposition: {
                    es: ["como"],
                    en: ["as", "like"],
                },
                conjunction: {
                    es: ["como"],
                    en: ["as"],
                },
            },
        },
        haud: {
            form: "haud",
            ipa: "'haud",
            type: {
                verb: {
                    es: ["saludar"],
                    en: ["to salute"],
                },
            },
        },
        kenen: {
            form: "kénen",
            ipa: "'ke:nen",
            type: {
                noun: {
                    es: ["perro"],
                    en: ["dog", "puppy"],
                },
            },
        },
        hosr: {
            form: "hǿsr",
            ipa: "'hø:sa",
            type: {
                noun: {
                    es: ["casa", "hogar"],
                    en: ["home", "house"],
                },
            },
        },
        iger: {
            form: "iger",
            ipa: "i.a",
            type: {
                preposition: {
                    es: ["para"],
                    en: ["for"],
                },
                phrase: {
                    es: ["para que"],
                    en: ["in order to"],
                },
            },
        },
        inen: {
            form: "inen",
            ipa: "inen",
            type: {
                preposition: {
                    es: ["hasta"],
                    en: ["till", "until"],
                },
            },
        },
        so: {
            form: "só",
            ipa: "so:",
            type: {
                phrase: {
                    es: ["en contra de"],
                    en: ["against the"],
                },
                preposition: {
                    es: ["contra"],
                    en: ["against"],
                },
            },
        },
        odnyk: {
            form: "ódnyk",
            ipa: "'ounɪk",
            type: {
                noun: {
                    es: ["bosque"],
                    en: ["woods", "forest"],
                }
            }
        },
        odr: {
            form: "ódr",
            ipa: "'ouda",
            type: {
                noun: {
                    es: ["árbol"],
                    en: ["tree"],
                }
            }
        },
        ogk: {
            form: "ógk",
            ipa: "'ouk",
            type: {
                noun: {
                    es: ["persona"],
                    en: ["person"],
                },
                pronoun: {
                    es: ["alguien"],
                    en: ["someone", "anyone"],
                }
            }
        },
        midas: {
            form: "midas",
            ipa: "'midas",
            type: {
                noun: {
                    es: ["madera"],
                    en: ["timber", "wood", "wooden"],
                },
            }
        },
        henen: {
            form: "henen",
            ipa: "'henen",
            type: {
                noun: {
                    es: ["aire"],
                    en: ["air"],
                },
            }
        },
        skelv: {
            form: "skélv",
            ipa: {
                root: "'ske:lf",
                inflected: "'ske:lv",
            },
            type: {
                noun: {
                    es: ["pelea"],
                    en: ["fight"],
                },
                verb: {
                    es: ["luchar", "pelear"],
                    en: ["to fight"],
                },
            },
        },
        skal: {
            form: "skål",
            ipa: "'ska:l",
            type: {
                verb: {
                    es: ["ir", "venir"],
                    en: ["to come", "to go"],
                },
            },
        },
        skeiv: {
            form: "skéiv",
            ipa: {
                root: "'ski:f",
                inflected: "'ski:v",
            },
            type: {
                verb: {
                    es: ["poner", "situar"],
                    en: ["to place", "to put"],
                },
            },
        },
        skeivr: {
            form: "skéivr",
            ipa: "'ski:va",
            type: {
                noun: {
                    es: ["situación"],
                    en: ["situation"],
                },
            },
        },
        skaven: {
            form: "skåven",
            ipa: "'ska:ven",
            type: {
                noun: {
                    es: ["sicario", "verdugo"],
                    en: ["executioner", "hitman"],
                },
            },
        },
        skeldr: {
            form: "skeldr",
            ipa: "'skelda",
            type: {
                noun: {
                    es: ["asesino"],
                    en: ["assassin"],
                },
                verb: {
                    es: ["asesinar", "matar"],
                    en: ["to assassinate", "to kill"],
                },
            },
        },
        stolkr: {
            form: "stólkr",
            ipa: "'sto:lka",
            type: {
                noun: {
                    es: ["perra", "prostituta"],
                    en: ["bitch", "prostitute"],
                },
            },
        },
        vagnr: {
            form: "ꝟågnr",
            ipa: "'va:hna",
            type: {
                adjective: {
                    es: ["irascible", "furioso"],
                    en: ["angry", "furious", "irascible", "raging", "techy", "testy"],
                },
                noun: {
                    es: ["furia", "ira"],
                    en: ["anger", "fury", "rage"],
                },
            },
        },
        skemd: {
            form: "skémd",
            ipa: {
                root: "'ske:m",
                inflected: "'ske:md",
            },
            type: {
                verb: {
                    es: ["defender"],
                    en: ["to defend"],
                },
            },
        },
        skor: {
            form: "skǿr",
            ipa: {
                root: "'skø:a",
                inflected: "'skø:ɾ",
            },
            type: {
                noun: {
                    es: ["obscuridad", "oscuridad"],
                    en: ["darkness"],
                },
                adjective: {
                    es: ["obscuro", "oscuro"],
                    en: ["dark"],
                },
                verb: {
                    es: ["obscurecer", "oscurecer"],
                    en: ["to darken"],
                },
            },
        },
        skrol: {
            form: "skról",
            ipa: {
                root: "'skɾou",
                inflected: "'skɾoul",
            },
            type: {
                noun: {
                    es: ["alma", "espíritu"],
                    en: ["soul", "spirit"],
                },
            },
        },
        kore: {
            form: "kóre",
            ipa: "'kouɾe",
            type: {
                noun: {
                    es: ["corazón", "motor", "núcleo"],
                    en: ["core", "engine", "heart", "motor"],
                },
            },
        },
        heilr: {
            form: "hélr",
            ipa: "'heɪla",
            type: {
                noun: {
                    es: ["sol"],
                    en: ["sun"],
                },
            },
        },
        solen: {
            form: "sólen",
            ipa: "'soulen",
            type: {
                noun: {
                    es: ["agua", "mar", "océano", "río"],
                    en: ["ocean", "river", "sea", "water"],
                },
            },
        },
        solenskat: {
            form: "sólenskåt",
            ipa: {
                root: "'soulenskat",
                inflected: "soulen'ska:t",
            },
            type: {
                verb: {
                    es: ["navegar"],
                    en: ["to sail"],
                },
            },
        },
        spor: {
            form: "spǿr",
            ipa: {
                root: "'spø:a",
                inflected: "'spø:ɾ",
            },
            type: {
                verb: {
                    es: ["avistar", "mirar", "observar", "ver"],
                    en: ["to observe", "to look", "to see", "to sight", "to watch"],
                },
            },
        },
        stog: {
            form: "stóg",
            ipa: {
                root: "'stouh",
                inflected: "'stoug",
            },
            type: {
                verb: {
                    es: ["tener"],
                    en: ["to have"],
                },
            },
        },
        tolv: {
            form: "tólv",
            ipa: {
                root: "'to:lf",
                inflected: "'to:lv",
            },
            type: {
                verb: {
                    es: ["decir", "hablar"],
                    en: ["to say", "to speak"],
                },
            },
        },
        úngef: {
            form: "úngef",
            ipa: "'ʉ:ŋgef",
            type: {
                verb: {
                    es: ["escapar", "huir"],
                    en: ["to escape", "to flee", "to run away"],
                },
            },
        },
        ferv: {
            form: "férv",
            ipa: {
                root: "'fe:ɾf",
                inflected: "'fe:ɾv",
            },
            type: {
                verb: {
                    es: ["necesitar"],
                    en: ["to need"],
                },
            },
        },
        vlod: {
            form: "vlǿd",
            ipa: "'vlø:d",
            type: {
                adjective: {
                    es: ["feroz"],
                    en: ["fierce"],
                },
            },
        },
        vogk: {
            form: "ꝟógk",
            ipa: "'vouk",
            type: {
                noun: {
                    es: ["carne"],
                    en: ["flesh", "meat"],
                },
            },
        },
        var: {
            form: "ꝟår",
            ipa: "'va:",
            type: {
                preposition: {
                    es: ["a través"],
                    en: ["through", "throughout"],
                },
            },
        },
        veifil: {
            form: "ꝟéifil",
            ipa: "'vi:fil",
            type: {
                auxiliary: {
                    es: ["deber"],
                    en: ["may", "might", "must", "should"],
                },
            },
        },
        vekt: {
            form: "vékt",
            ipa: "'fe:gt",
            type: {
                noun: {
                    es: ["lugar", "sitio", "zona"],
                    en: ["site", "place", "zone"],
                },
            },
        },
        vogend: {
            form: "ꝟógend",
            ipa: {
                root: "'vo:gen",
                inflected: "'vo:gend",
            },
            type: {
                noun: {
                    es: ["hombre"],
                    en: ["man"],
                },
            },
        },
        vom: {
            form: "vóm",
            ipa: "'fo:m",
            type: {
                adverb: {
                    es: ["acá", "aquí"],
                    en: ["here"],
                },
            },
        },
        ins: {
            form: "ins",
            ipa: "'ins",
            type: {
                preposition: {
                    es: ["de", "desde"],
                    en: ["from", "of", "since"],
                },
            },
        },
        nar: {
            form: "når",
            ipa: "'na:",
            type: {
                adverb: {
                    es: ["ni", "no", "ningún", "ninguno"],
                    en: ["neither", "no", "none", "nor", "not"],
                },
            },
        },
        flaus: {
            form: "flaus",
            ipa: "'flaʊs",
            type: {
                determiner: {
                    es: ["todo"],
                    en: ["all"],
                },
                pronoun: {
                    es: ["todo"],
                    en: ["everything"],
                },
            },
        },
        her: {
            form: "hér",
            ipa: "'he:",
            type: {
                adverb: {
                    es: ["cuando", "cuándo"],
                    en: ["when"],
                },
                conjunction: {
                    es: ["si"],
                    en: ["if"],
                },
            },
        },
        fnegel: {
            form: "fnégel",
            ipa: "'fne:gel",
            type: {
                pronoun: {
                    es: ["como sea", "cuando sea", "donde sea", "lo que sea"],
                    en: ["however", "whatever", "whenever"],
                },
            },
        },
        ske: {
            form: "ske",
            ipa: "'ske",
            type: {
                adverb: {
                    es: ["así"],
                    en: ["so", "that", "thereby", "thus"],
                },
            },
        },
        veis: {
            form: "ꝟeis",
            ipa: "'vaɪs",
            type: {
                adverb: {
                    es: ["cómo"],
                    en: ["how"],
                },
            },
        },
        vot: {
            form: "ꝟót",
            ipa: "'vo:t",
            type: {
                adverb: {
                    es: ["por qué"],
                    en: ["why"],
                },
            },
        },
        veid: {
            form: "ꝟeid",
            ipa: "'vaɪd",
            irregular: {
                verb: {
                    nonPast: {
                        form: "ꝟeidr",
                        ipa: "'vaɪda",
                    },
                    imperfectPreterite: {
                        form: "ꝟånsege",
                        ipa: "'va:ŋsege",
                    },
                    perfectPreterite: {
                        form: "vǿnde",
                        ipa: "'fø:nde",
                    },
                },
            },
            type: {
                verb: {
                    es: ["estar", "haber"],
                    en: ["to be"],
                },
            },
        },
        fev: {
            form: "fév",
            ipa: {
                root: "'feɪf",
                inflected: "'feɪv",
            },
            type: {
                adjective: {
                    es: ["favorito",],
                    en: ["favorite", "favourite"],
                },
                verb: {
                    es: ["favorecer"],
                    en: ["to favor"],
                },
            },
        },
        gjot: {
            form: "gjøt",
            ipa: "'jœ:t",
            type: {
                adverb: {
                    es: ["aún"],
                    en: ["yet"],
                },
            },
        },
        midas: {
            form: "midas",
            ipa: "'midas",
            type: {
                noun: {
                    es: ["madera"],
                    en: ["wood", "wooden"],
                },
            },
        },
        haks: {
            form: "håks",
            ipa: "'ha:ks",
            type: {
                noun: {
                    es: ["hacha"],
                    en: ["ax", "axe"],
                },
                verb: {
                    es: ["hachar"],
                    en: ["to ax", "to axe"],
                },
            },
        },
        osk: {
            form: "øsk",
            ipa: "'œ:sk",
            irregular: {
                verb: {
                    nonPast: {
                        form: "ské",
                        ipa: "'ske:",
                    },
                    imperfectPreterite: {
                        form: "skévinr",
                        ipa: "'ske:vina",
                    },
                    perfectPreterite: {
                        form: "skǿne",
                        ipa: "'skø:ne",
                    },
                },
            },
            type: {
                verb: {
                    es: ["ser"],
                    en: ["to be"],
                },
            },
        },
        spros: {
            form: "sprós",
            ipa: "'spɾo:s",
            type: {
                preposition: {
                    es: ["atrás"],
                    en: ["back"],
                },
                noun: {
                    es: ["espalda"],
                    en: ["back"],
                },
            },
        },
        sprosvar: {
            form: "sprósvår",
            ipa: "'spɾo:sva",
            type: {
                preposition: {
                    es: ["detrás de", "por detrás"],
                    en: ["at the back of", "in back of", "on the other side of"],
                },
                adverb: {
                    es: ["detrás"],
                    en: ["behind"],
                },
            },
        },
        hnod: {
            form: "hnød",
            ipa: "'ɲœ:d",
            type: {
                noun: {
                    es: ["noche"],
                    en: ["night"],
                },
            },
        },
        holst: {
            form: "hølst",
            ipa: "'hœ:lst",
            type: {
                adjective: {
                    es: ["siguiente"],
                    en: ["next"],
                },
                adverb: {
                    es: ["después", "luego"],
                    en: ["after", "later", "then"],
                },
            },
        },
        tviet: {
            form: "tvíet",
            ipa: "'tvi:et",
            type: {
                adverb: {
                    es: ["mañana"],
                    en: ["tomorrow"],
                },
            },
        },
        yngjel: {
            form: "yngjel",
            ipa: "'i:ŋ.jel",
            type: {
                adverb: {
                    es: ["además", "incluso", "también"],
                    en: ["also", "besides", "even", "too"],
                },
            },
        },
        ygnr: {
            form: "ygnr",
            ipa: "'ɪ:hna",
            irregular: {
                verb: {
                    nonPast: {
                        form: "ygnr",
                        ipa: "'ɪ:hna",
                    },
                    imperfectPreterite: {
                        form: "ygvinr",
                        ipa: "'ɪ:hvina",
                    },
                    perfectPreterite: {
                        form: "ygnete",
                        ipa: "'ɪ:hnete",
                    },
                }
            },
            type: {
                noun: {
                    es: ["fuego", "quemadura"],
                    en: ["fire", "scorch"],
                },
                verb: {
                    es: ["incendiar", "quemar"],
                    en: ["to burn", "to ignite", "to set on fire"],
                },
            },
        },
        ygnaskeivr: {
            form: "ygnaskéivr",
            ipa: "ɪhna'ski:va",
            type: {
                noun: {
                    es: ["incendio"],
                    en: ["burning"],
                },
            },
        },
    },
}

export { Dictionary, Abbreviations };