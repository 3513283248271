import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainIndex from './pages/web/MainIndex';

function App() {
	return (
		<Router>
			<Routes>

				<Route path="/" element={<MainIndex />} />

			</Routes>
		</Router>
	);
}

export default App;