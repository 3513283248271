class Inflector
{
    containsPhoneme (word, symbol = "", repeated = false)
    {
        let found = false;
        let regex = null;

        if (word.ipa.root)
        {
            Object.values(word.ipa).forEach(ipa => {
                if (!found) {
                    if (repeated) {
                        regex = new RegExp(`(${symbol}).*(${symbol})`);
                    } else {
                        regex = new RegExp(`(${symbol})`);
                    }

                    found = regex.test(ipa);
                }
            });
        }
        else
        {
            if (repeated) {
                regex = new RegExp(`(${symbol}).*(${symbol})`);
            } else {
                regex = new RegExp(`(${symbol})`);
            }

            found = regex.test(word.ipa);
        }

        return found;
    }

    contains (word, letter = "", repeated = false)
    {
        let found = false;
        let regex = null;

        if (repeated) {
            regex = new RegExp(`(${letter}).*(${letter})`);
        } else {
            regex = new RegExp(`(${letter})`);
        }

        found = regex.test(word.form);

        return found;
    }

    isPenultLetter (word, letters = [])
    {
        let found = false;
        let penult = word.form.substr(-2)[0];

        letters.forEach(l => {
            if (!found && penult === l) {
                found = true;
            }
        });

        return found;
    }

    isLastLetter (word, letters = [])
    {
        let found = false;
        let last = word.form.substr(-1)[0];

        letters.forEach(l => {
            if (!found && last === l) {
                found = true;
            }
        });

        return found;
    }

    do (word)
    {
        let inflections = {
            adjective: null,
            noun: null,
            verb: null,
        }

        let types = Object.keys(word.type);
        let ipa = word.ipa.inflected ?? word.ipa;

        if (types.includes("adjective"))
        {
            let buffer = {
                plural: {
                    form: null,
                    ipa: null,
                }
            }

            if (word.irregular && word.irregular.adjective !== undefined)
            {
                buffer = word.irregular.adjective;
            }
            else
            {
                let suffix = null;

                if (word.form.endsWith("en") && word.form.length > 4) {
                    if (suffix === null) {
                        suffix = {
                            form: "",
                            ipa: "",
                        }
                    }
                }
    
                if (word.form.endsWith("lv")) {
                    if (suffix === null) {
                        suffix = {
                            form: "í",
                            ipa: "i",
                        }
                    }
                }
    
                if (word.form.endsWith("s") && this.isPenultLetter(word, "aåeéiíoóøǿuúy".split(""))) {
                    if (suffix === null) {
                        suffix = {
                            form: "",
                            ipa: "",
                        }
                    }
                }
    
                if (word.form.endsWith("r") && this.isPenultLetter(word, "aåeéiíoóøǿuúy".split(""))) {
                    if (suffix === null) {
                        suffix = {
                            form: "a",
                            ipa: "a",
                        }
                    }
                }
    
                if (word.form.endsWith("r") && this.isPenultLetter(word, "aåeéiíoóøǿuúy".split(""))) {
                    if (suffix === null) {
                        suffix = {
                            form: "a",
                            ipa: "a",
                        }
                    }
                }
    
                if (word.form.endsWith("r") || word.form.endsWith("re")) {
                    if (suffix === null) {
                        suffix = {
                            form: "",
                            ipa: "",
                        }
                    }
                }

                if (suffix === null) {
                    suffix = {
                        form: "r",
                        ipa: "a",
                    }
                }

                buffer.plural.form = `${word.form}${suffix.form}`;
                buffer.plural.ipa = `${ipa}${suffix.ipa}`;
            }

            inflections.adjective = buffer;
        }

        if (types.includes("noun"))
        {
            let buffer = {
                plural: {
                    form: null,
                    ipa: null,
                }
            }

            if (word.irregular && word.irregular.noun !== undefined)
            {
                buffer = word.irregular.noun;
            }
            else
            {
                let suffix = null;

                if (word.form.endsWith("en") && word.form.length > 4) {
                    if (suffix === null) {
                        suffix = {
                            form: "",
                            ipa: "",
                        }
                    }
                }
    
                if (word.form.endsWith("lv")) {
                    if (suffix === null) {
                        suffix = {
                            form: "í",
                            ipa: "i",
                        }
                    }
                }
    
                if (word.form.endsWith("s") && this.isPenultLetter(word, "aåeéiíoóøǿuúy".split(""))) {
                    if (suffix === null) {
                        suffix = {
                            form: "",
                            ipa: "",
                        }
                    }
                }
    
                if (word.form.endsWith("r") && this.isPenultLetter(word, "aåeéiíoóøǿuúy".split(""))) {
                    if (suffix === null) {
                        suffix = {
                            form: "a",
                            ipa: "a",
                        }
                    }
                }
    
                if (word.form.endsWith("r") || word.form.endsWith("re")) {
                    if (suffix === null) {
                        suffix = {
                            form: "",
                            ipa: "",
                        }
                    }
                }

                if (suffix === null) {
                    suffix = {
                        form: "r",
                        ipa: "a",
                    }
                }

                buffer.plural.form = `${word.form}${suffix.form}`;
                buffer.plural.ipa = `${ipa}${suffix.ipa}`;
            }

            inflections.noun = buffer;
        }

        if (types.includes("verb"))
        {
            let buffer = {
                nonPast: {
                    form: null,
                    ipa: null,
                },
                imperfectPreterite: {
                    form: null,
                    ipa: null,
                },
                perfectPreterite: {
                    form: null,
                    ipa: null,
                },
            }

            if (word.irregular)
            {
                buffer = word.irregular.verb;
            }
            else
            {
                let suffix = null;

                if (word.form.endsWith("gk") || word.form.endsWith("gt") || word.form.endsWith("kt") || word.form.endsWith("lv")) {
                    if (suffix === null) {
                        suffix = {
                            form: "en",
                            ipa: "en",
                        }
                    }
                }
    
                if (word.form.endsWith("r")) {
                    if (suffix === null) {
                        if (this.isPenultLetter(word, "aåeéiíoóøǿuúy".split(""))) {
                            suffix = {
                                form: "e",
                                ipa: "e",
                            }
                        } else {
                            suffix = {
                                form: "",
                                ipa: "",
                            }
                        }
                    }
                }
                
                if (this.containsPhoneme(word, "e", true) || this.contains(word, "ú") || this.contains(word, "éi") || word.form.endsWith("ks") || word.form.endsWith("d")) {
                    if (suffix === null) {
                        suffix = {
                            form: "r",
                            ipa: "a",
                        }
                    }
                }
    
                if (suffix === null) {
                    suffix = {
                        form: "e",
                        ipa: "e",
                    }
                }

                buffer.nonPast.form = `${word.form}${suffix.form}`;
                buffer.nonPast.ipa = `${ipa}${suffix.ipa}`;

                suffix = null;

                if (word.form.endsWith("v")) {
                    if (suffix === null) {
                        suffix = {
                            form: "inr",
                            ipa: "ina",
                        }
                    }
                }

                let letras = "dfghklmnpstv".split("");

                if (this.isPenultLetter(word, letras) && this.isLastLetter(word, letras)) {
                    if (suffix === null) {
                        suffix = {
                            form: "í",
                            ipa: "i",
                        }
                    }
                }

                if (suffix === null) {
                    suffix = {
                        form: "vinr",
                        ipa: "vina",
                    }
                }

                buffer.imperfectPreterite.form = `${word.form}${suffix.form}`;
                buffer.imperfectPreterite.ipa = `${ipa}${suffix.ipa}`;
                buffer.imperfectPreterite.ipa = buffer.imperfectPreterite.ipa.replace(/gv/gi, "hv");
                buffer.imperfectPreterite.ipa = buffer.imperfectPreterite.ipa.replace(/kv/gi, "ɕv");

                suffix = null;

                if (word.form.endsWith("g") || word.form.endsWith("sk")) {
                    if (suffix === null) {
                        suffix = {
                            form: "je",
                            ipa: "je",
                        }
                    }
                }

                if (suffix === null) {
                    suffix = {
                        form: "ige",
                        ipa: "je",
                    }
                }

                buffer.perfectPreterite.form = `${word.form}${suffix.form}`;
                buffer.perfectPreterite.ipa = `${ipa}${suffix.ipa}`;
            }

            inflections.verb = buffer;
        }

        return inflections;
    }
}

export default Inflector;