const conlang = "vlǿdigk";
const author = "Ignaz Volkov";

const Texts = {
    title: conlang,
    author: {
        name: author,
        full: {
            es: `por ${author}`,
            en: `by ${author}`,
        }
    },
    searcher: {
        placeholder: {
            es: "Buscar",
            en: "Search",
        },
        filterBy: {
            es: "Palabras en",
            en: "Words in",
        },
        results: {
            es: "Resultados encontrados",
            en: "Results found",
        },
        conlang: conlang,
        language: {
            es: "Español",
            en: "English",
        },
    },
    inflections: {
        adjective: {
            plural: {
                es: "adj. pl.",
                en: "adj. pl.",
            },
        },
        noun: {
            plural: {
                es: "s. pl.",
                en: "n. pl.",
            },
        },
        verb: {
            nonPast: {
                es: "v. npsdo.",
                en: "v. npst.",
            },
            imperfectPreterite: {
                es: "v. pret. imp.",
                en: "v. imp. pret.",
            },
            perfectPreterite: {
                es: "v. pret. perf.",
                en: "v. perf. pret.",
            },
        },
    },
    menu: {
        manual: {
            es: "Manual",
            en: "Manual",
        },
        dictionary: {
            es: "Diccionario",
            en: "Dictionary",
        }
    }
};

export default Texts;