import "./styles.css";
import { useState } from "react";
import SkullImg from "../../images/skull.png";
import MenuImg from "../../images/menu.png";

const Navbar = (props) => {

    const [language, setLanguage] = useState(props.texts);

    return (<>
		<div className="navbar">
			<div className="logo">
                <div className="logo-img" style={{backgroundImage: `url(${SkullImg})`}}></div>
                <div>
    				<h2>{ props.title }</h2>
                    <small>{ props.author }</small>
                </div>
			</div>
            <div className="menu">
                {/*
                <div className="btn-opt">{ props.manual }</div>
                <div className="btn-opt">{ props.dictionary}</div>
                */}
                <div className="btn-tag" onClick={e => props.onChangeLanguage()}>
                    { props.language.toUpperCase() }
                </div>
                {/*
                <div className="btn-icon btn-menu" style={{backgroundImage: `url(${MenuImg})`}}></div>
                */}
            </div>
		</div>
    </>);

}

export default Navbar;